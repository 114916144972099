(function () {
  'use strict';

  /* @ngdoc object
   * @name trek
   * @description
   *
   */
  angular
    .module('trek', [
      'env',
      'ngAria',
      'ngMaterial',
      'ui.router',
      'home',
      'auth',
      'events',
      'people',
      'events.vehicles',
      'events.guests',
      'events.deposits',
      'settings',
      'login',
      'perfect_scrollbar',
      'restangular',
      'md.data.table',
      'ngFileSaver',
      'ngPapaParse'
    ])
    .config(config)
    .run(run)
    .constant('PAGE_SIZE', 10);

  function config($mdDateLocaleProvider, $httpProvider, $compileProvider, RestangularProvider, API_URL) {

    // Date and time settings
    $mdDateLocaleProvider.firstDayOfWeek = 1; // start week on Monday
    $mdDateLocaleProvider.formatDate = function (date) {
      var m = moment(date);
      return m.isValid() ? m.format('DD / MM / YYYY') : '';
    };

    $mdDateLocaleProvider.parseDate = function(dateString) {
      var m = moment(dateString, 'L', true);
      return m.isValid() ? m.toDate() : new Date(NaN);
    };

    // Auth
    $httpProvider.interceptors.push('AuthInterceptor');

    // API settings
    RestangularProvider.setBaseUrl(API_URL);
    RestangularProvider.addResponseInterceptor(responseInterceptor);
    // RestangularProvider.setDefaultHttpFields({cache:true});

    // Extract data from the response data envelope
    function responseInterceptor(data, operation) {
      if (operation == 'getList' && data.hasOwnProperty('data')) {
        var response = data['data'];

        if (data.hasOwnProperty('meta')) {
          response.meta = data.meta;
        }
        return response;
      }

      return data;
    }

    // Don't add ng-scope and all those debugging classes
    // https://code.angularjs.org/1.5.5/docs/guide/production
    $compileProvider.debugInfoEnabled(false);
  }

  function run($rootScope, $state, $stateParams, $window, AuthTokenFactory, Restangular) {
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    $rootScope.goBack = goBack;
    $rootScope.openMenu = openMenu;
    $rootScope.events = null;
    $rootScope.getEvent = getEvent;
    $rootScope.role = $window.localStorage.getItem('role');

    // Take user to the state they tried before login
    $rootScope.stateAfterLogin = null;

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {

      console.log('Entering state:', toState.name);
      var params = [];
      angular.forEach(toParams, function (p, key) {
        params.push(key + ' = ' + p);
      });

      if (params.length) {
        console.log('Params:        ', params.join(', '));
      }

      // A state is restricted (requires login) if authentication is not defined or is defined and set to true
      var isRestricted = angular.isUndefined(toState.authentication) || !!toState.authentication;

      // Check for a token if state is restricted
      if (isRestricted && !AuthTokenFactory.hasToken()) {
        console.log('State is restricted and no token was found');

        // Stop processing state change
        event.preventDefault();

        // Record the desired state so user can be taken there after login
        $rootScope.stateAfterLogin = toState;

        // Go to login page
        $state.go('login.login');
      }

      // Load events (for the menu) if not loaded already
      if ($rootScope.events === null) {
        reloadEvents();
      }
    });

    function reloadEvents() {
      if (AuthTokenFactory.hasToken()) {
        Restangular.all('events').getList().then(function(events) {
          $rootScope.events = events;
        });
      }
    }

    function goBack() {
      $window.history.back();
    }

    function openMenu($mdOpenMenu, ev) {
      $mdOpenMenu(ev);
    }

    function getEvent(id) {
      if ($rootScope.events === null) {
        reloadEvents();
      }
      for (var i = 0; i < $rootScope.events.length; i++) {
        if ($rootScope.events[i].id == id) {
          return $rootScope.events[i];
        }
      }
      return null;
    }
  }

}());
